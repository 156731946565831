import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Contact" />
    <p>
      I'm not a huge social media person, but you can find me on a few places:
    </p>
    <ul>
      <li>
        <a href="https://github.com/marcmaniez" target="_blank">
          GitHub
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/marcmaniez" target="_blank">
          LinkedIn
        </a>
      </li>
      <li>
        <a href="https://twitter.com/marcmaniez" target="_blank">
          Twitter
        </a>
      </li>
      <li>
        <a href="https://online-go.com/player/852166/" target="_blank">
          OGS
        </a>
      </li>
      <li>
        <a href="mailto:someEmailAddressTBD@marcmaniez.com" target="_blank">
          Email me
        </a>
      </li>
    </ul>
  </Layout>
)

export default Contact
